import { useEffect } from "react";
import { getSelectorsByUserAgent } from "react-device-detect";
import {
  APP_STORE_URL_ANDROID,
  APP_STORE_URL_IOS,
} from "../../constants/app.contants";

const AppStorePage = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      let userAgent = window.navigator.userAgent;
      const { isAndroid, isIOS } = getSelectorsByUserAgent(userAgent);

      if (isAndroid) {
        window.location.href = APP_STORE_URL_ANDROID;
      } else if (isIOS) {
        window.location.href = APP_STORE_URL_IOS;
      } else {
        window.location.href = "./stores";
      }
    }
  }, []);

  return null;
};

export default AppStorePage;
